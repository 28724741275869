<ng-container *ngIf="items$ | async as items">
  <div *ngIf="items.length">
    <div *ngFor="let item of items" class="row">
      <div class="col-2 pe-0">
        <div
          class="sub-item rounded text-center"
          [ngClass]="{
            '': item.productData.ProductType == 1,
            'bg-secondary': item.productData.ProductType == 3
          }"
        >
          <img
            alt=""
            class="portal-product-img mb-2 me-3 rounded"
            src="{{ item.productData.PrimaryImage | contentImage : '6' }}"
          />
        </div>
      </div>
      <div class="col-10 ps-lg-0">
        <div class="row">
          <div class="col-12 text-start ps-lg-3 ps-4">
            <p class="font-size-14 fw-bold mb-2">
              {{ item.productData.DisplayName }}
            </p>
            <div class="row">
              <div class="col-6">
                <p class="font-size-12">
                  <span class="fw-bold pe-2"> {{ 'checkout.cost' | translate }} </span>
                  {{ item.productData.MerchantPrice / 100 | FortressCurrencyPipe : '£' }}
                </p>
              </div>
              <div class="col-6 text-end pe-4">
                <button
                  (click)="remove(item)"
                  class="btn py-0"
                  attr.aria-label="{{ 'cart.remove.item.screenreader' | translate }}"
                >
                  <span class="icon material-icons align-middle"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!items.length" class="font-size-14">
    <p [translate]="'checkout.empty-basket'">Your basket is empty!</p>
  </div>
</ng-container>
