<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <h1>{{ 'home.page.title' | translate }} {{ name$ | async }}</h1>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container mt-3 mb-4">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<ng-container *notInProductCode="[productCode.Tier4, productCode.Kids]">
  <fgb-upgrade-section></fgb-upgrade-section>
</ng-container>

<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="container mt-4">
    <div class="row">
      <div class="col-6">
        <fgb-member-card></fgb-member-card>
      </div>
      <div class="col-6">
        <fgb-vouchers></fgb-vouchers>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container my-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
</ng-container>

<ng-container *notInProductCode="[productCode.Free]">
  <!--Scorecard carousel-->
  <div class="mt-lg-4">
    <fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true"></fgbcl-scorecard-carousel>
  </div>
</ng-container>

<div class="container">
  <!-- Featured Rewards -->
  <fgbcl-featured-rewards></fgbcl-featured-rewards>

  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>

  <!--Featured Games-->
  <!-- <fgb-featured-surveys></fgb-featured-surveys> -->
</div>
