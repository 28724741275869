<div class="banner" role="banner" *ifScreenSize="screenSize.Desktop">
  <h1>{{ name$ | async }}</h1>
</div>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container mt-3 mb-4">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-6">
      <ng-container *ifScreenSize="screenSize.Desktop">
        <h5 class="mb-3 mt-lg-0">{{ 'account.member.card.title' | translate }}</h5>

        <fgb-member-card></fgb-member-card>
      </ng-container>
      <ng-container *inProductCode="productCode.Free">
        <div class="mb-4">
          <fgb-subscriptions-list></fgb-subscriptions-list>
        </div>
      </ng-container>
    </div>

    <div class="col-12 col-lg-6">
      <h5 class="my-lg-3 my-3 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
