<div class="banner justify-content-center d-none d-lg-flex" role="banner">
  <h1 class="text-white">{{ 'cart.checkout.page.portal.products.title' | translate }}</h1>
</div>
<div class="container">
  <div class="back-mobile-bar d-flex d-lg-none my-2 align-items-center font-size-14">
    <!-- Back Button -->
    <a class="text-danger fw-bold me-2" (click)="goBack()">
      {{ 'cart.checkout.page.portal.products.back' | translate }}
    </a>
    <!-- Checkout Title -->
    <div class="ms-auto">
      <div class="checkout-title me-2">
        {{ 'cart.checkout.page.portal.products.checkout.title' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-12 mt-lg-3">
      <h5 class="mb-3 item-summary-heading">{{ 'cart.checkout.page.portal.products.items.title' | translate }}</h5>
      <fgb-cart-item-subscriptions></fgb-cart-item-subscriptions>
      <button class="btn text-primary fw-bold d-none d-lg-block ps-0" (click)="goBack()">
        <span class="font-size-14 text-danger">{{ 'cart.checkout.page.portal.products.back' | translate }}</span>
      </button>
    </div>
    <div class="col-lg-5 col-12 mt-3 mt-lg-5">
      <!--EO giftcode-->
      <div class="card border bg-white rounded py-2 mb-3">
        <h5 class="summary-heading mb-3">
          {{ 'cart.checkout.page.portal.products.summary.title' | translate }}
        </h5>
        <div class="row text-black">
          <div class="col-6 font-size-12">
            <span>{{ 'cart.checkout.items' | translate }}</span>
          </div>
          <div class="col-6 font-size-12 text-end">
            {{ totalCost / 100 | FortressCurrencyPipe : '£' }}
          </div>
        </div>

        <hr class="w-100" />
        <div class="row text-black fw-bold">
          <div class="col-6 fw-bold font-size-12">
            <span>{{ 'cart.checkout.total' | translate }}</span>
          </div>
          <div class="col-6 fw-bold font-size-12 text-end">
            {{ totalPrice / 100 | FortressCurrencyPipe : '£' }}
          </div>
        </div>
        <!--start of code addition -->
        <div class="row text-info font-size-12" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
          <div class="col-12 heading-text-two">Discount Code(s):</div>
          <ng-container *ngFor="let giftCode of appliedGiftCodes">
            <div class="col-9 heading-text"><button (click)="removeGiftCode(giftCode.Code)">X</button> {{ giftCode.Code }}</div>
            <div class="col-3 heading-text text-end">
              <span *ngIf="giftCode.EntitlementValueType == 0"
                >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
              >
              <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
              <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
            </div>
          </ng-container>
        </div>
        <!--EO gift code summary-->
      </div>
      <div class="card border rounded">
        <h5 class="payment-heading">
          {{ 'cart.checkout.page.portal.products.payment.title' | translate }}
        </h5>
        <fgbcl-errors></fgbcl-errors>
        <ng-container *ngIf="items$ | async as items">
          <button
            class="btn btn-tertiary text-primary mt-2 w-100 font-size-14 fw-bold"
            [disabled]="items.length == 0"
            (click)="openPaymentModal(paymentModal)"
          >
            {{ 'cart.checkout.page.portal.products.checkout' | translate }}
          </button></ng-container
        >
      </div>
    </div>
  </div>
</div>

<ng-template #paymentModal let-modal>
  <div class="payment-modal">
    <!-- Payment Provider Form -->
    <div class="payment-container">
      <div class="payment-modal-header">
        <h6 class="m-0 font-size-14 fw-bold">
          {{ 'cart.checkout.page.portal.products.payment.modal.title' | translate }}
        </h6>
        <a class="close-btn ms-auto" (click)="modal.dismiss()">
          <i class="material-icons text-info">close</i>
        </a>
      </div>
      <div class="payment-div-container">
        <div class="mt-5" *ngIf="paymentLoading">
          <fgbcl-loading></fgbcl-loading>
        </div>
        <div class="mb-3" id="paymentDiv"></div>
        <div class="text-center">
          <fgbcl-errors></fgbcl-errors>
        </div>
      </div>
      <div class="payment-modal-footer">
        <button class="btn btn-modal-cancel text-danger font-size-14 fw-bold ms-auto" (click)="modal.dismiss('Cross click')">
          Cancel
        </button>
      </div>
    </div>

    <ng-container *ngIf="orderCompleted">
      Membership upgraded successfully
      <button class="btn btn-primary w-100 mb-3 mt-5 font-size-14 fw-bold" (click)="modal.dismiss('Cross click')">close</button>
    </ng-container>
  </div>
</ng-template>
