<div class="card border border-disabled">
  <div class="card-content px-3">
    <h5 class="mb-3 font-weight-bold text-capitalize text-primary">
      {{ 'voucher.title' | translate }}
    </h5>
    <span class="input-group pb-2">
      <input
        type="text"
        [(ngModel)]="voucherCode"
        placeholder="Insert Code Here"
        class="form-control"
        (keyup.enter)="redeemVoucher()"
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary icon text-white font-weight-bold material-icons py-0"
          type="button"
          (click)="redeemVoucher()"
          [disabled]="disableButton"
        >
          arrow_forward_ios
        </button>
      </div>
    </span>
  </div>
  <div class="voucher-errors">
    <div class="text-center">
      <fgbcl-errors></fgbcl-errors>
    </div>
    <div class="result text-center" *ngIf="redemptionResult">
      <div class="font-size-10 {{ successMessage }}" [ngClass]="redemptionResult === sucessfulRedemption ? 'text-success' : ''">
        {{ redemptionResult }}
      </div>
    </div>
  </div>
</div>
