<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card border rounded mb-3 mb-lg-0 text-white p-4 {{ bgtheme }}">
    <div class="d-flex justify-content-between">
      <!--Name and ID-->
      <div class="card-id text-start mb-4 {{ texttheme }}">
        <div class="mb-1 card-name d-flex align-items-center flex-wrap" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </div>
        <div class="d-flex font-size-10">
          <div>{{ memberCard.ProductCode }}</div>
          <div class="px-1">-</div>
          <div>{{ memberCard.ExternalRefNumber }}</div>
        </div>
      </div>

      <div class="card-logo-container d-flex justify-content-center align-items-center">
        <img
          *notInProductCode="[productCode.Kids]"
          class="card-logo mt-5"
          src="assets/images/memberships/{{ memberCard.ProductCode }}.png"
          draggable="false"
        />
        <img *inProductCode="[productCode.Kids]" class="child-logo mt-5" src="assets/images/logo.svg" draggable="false" />
      </div>
    </div>

    <!--Balances-->
    <div class="d-flex justify-content-start align-items-center {{ texttheme }}" *notInProductCode="[productCode.Kids]">
      <div class="card-points text-start border-white pe-4 font-size-14" *ngIf="pointsBalance$">
        <label class="mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
        <div class="">{{ 'member.card.points.balance' | translate }}</div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
