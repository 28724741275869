import { Component, OnInit } from '@angular/core';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-game-nav',
  templateUrl: './game-nav.component.html',
  styleUrls: ['./game-nav.component.scss'],
})
export class GameNavComponent implements OnInit {
  productCode = ProductCode;
  constructor() {}

  ngOnInit() {}
}
