import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LottoService, ProductCodeQuery } from '@fgb/core';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-lotto-detail-page',
  templateUrl: './lotto-detail-page.component.html',
  styleUrls: ['./lotto-detail-page.component.scss'],
  hostDirectives: [HideMobileNav],
})
export class LottoDetailPageComponent implements OnInit {
  id: number;

  constructor(private route: ActivatedRoute, private lottoService: LottoService, private productCodeQuery: ProductCodeQuery) {}

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);

    const isFreeMember = this.productCodeQuery.currentUserIsProductCode(ProductCode.Free);
    if (isFreeMember) {
      this.lottoService.lockAllItems();
    }
  }
}
